import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import CardHeader from "_components/CardHeader/CardHeader";
import CardFooter from "_components/CardFooter/CardFooter";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const LanguageCard = ({ bodyText, buttonLabel, headerText, isButtonDisabled, onClick, selectBox }) => {
    return (
        <Container className="fade-in">
            <Card className="locale-picker">
                <CardHeader value={headerText}/>
                <Card.Body>
                    <Row className="justify-content-md-center text-center">
                        <Col className="col-md-6">
                            <p>
                                {bodyText}
                            </p>
                            {selectBox}
                        </Col>
                    </Row>
                </Card.Body>
                <CardFooter buttonLabel={buttonLabel} isDisabled={isButtonDisabled} onClick={onClick}/>
            </Card>
        </Container>
    );
}

export default LanguageCard;