import { selectorFamily } from "recoil";
import { answersAtom, demographicsAtom } from "_atoms";
import { assessmentSelector } from "_selectors";
import { findMostRecentReplyForElement } from "_helpers/reply-helpers";
import { checkIfAnySkipGroupsAreSelected, getOptionSet } from "_helpers/element-container-helpers";

/**
 * Get the most recent non-deleted answer for a given item.
 */
const answerSelectorFamily = selectorFamily({
    key: 'answer',
    /**
     * Find the most recent non-deleted answer for a given item.
     * @param {string} itemId - The id of the item for which to find the answer
     * @return {function({get: *}): ?Answer} - Returns the most recent non-deleted answer object for the item
     */
    get: (itemId) => ({get}) => {
        /** @type Answer[] */
        const answers = get(answersAtom);

        return (
            /** @type ?Answer */
            findMostRecentReplyForElement(itemId, answers, 'item')
        );
    }
});

/**
 * Get the most recent non-deleted demographic for a given field.
 */
const demographicSelectorFamily = selectorFamily({
    key: 'demographic',
    /**
     * Find the most recent non-deleted demographic for a given field.
     * @param {string} fieldId - The id of the field for which to find the demographic
     * @return {function({get: *}): ?Demographic} - Returns the most recent non-deleted demographic object for that field
     */
    get: (fieldId) => ({get}) => {
        /** @type Demographic[] */
        const demographics = get(demographicsAtom);

        return (
            /** @type ?Demographic */
            findMostRecentReplyForElement(fieldId, demographics, 'field')
        );
    }
});

/**
 * Retrieve an option set based on its id.
 */
const optionSetSelectorFamily = selectorFamily({
    key: 'optionSet',
    /**
     * Retrieve an option set based on its id.
     * @param {Item} item - The item for which to find the optionSet
     * @return {function({get: *}): ?OptionSet} - Returns the optionSet object. When the optionSetId is null, returns null.
     * @throws {Error} - Throw an exception when an optionSetId is provided but no matching optionSet was found
     */
    get: (item) => ({get}) => {
        /** @type Assessment */
        const assessment = get(assessmentSelector);
        /** @type OptionSet[] */
        const optionSets = assessment?.optionSets || [];

        return (
            /** @type ?OptionSet */
            getOptionSet(item, optionSets)
        );
    }
});

/**
 * Check whether the field should be skipped due to one of its skip groups being selected.
 */
const skippedStatusSelectorFamily = selectorFamily({
    key: 'skippedStatus',
    /**
     * Check whether the field should be skipped due to one of its skip groups being selected.
     * @param {string[]} skipGroupIds - List of groups to look for in the demographics causing the field to be skipped
     * @return {function({get: *}): boolean} - Returns whether any of the field's skip groups are selected
     */
    get: (skipGroupIds) => ({get}) => {
        /** @type Demographic[] */
        const demographics = get(demographicsAtom);
        /** @type boolean */
        return checkIfAnySkipGroupsAreSelected(skipGroupIds, demographics);
    }
});

export {
    answerSelectorFamily,
    demographicSelectorFamily,
    optionSetSelectorFamily,
    skippedStatusSelectorFamily
};