import { api } from "./query-interceptors";

/**
 * Retrieve a respondent object from the API.
 * @param {string} respondentId - The id of the respondent to fetch
 * @param {AbortController} controller - The abort controller to cancel requests
 * @return {Promise} - Returns the axios request promise.
 */
export async function respondentGetQuery( respondentId, controller) {
    const requestOptions = {
        method: 'GET',
        params: {
            expand: 'user'
        },
        url: `/respondents/${respondentId}/`,
        signal: controller.signal
    };
    return api(requestOptions);
}

/**
 * Post revised respondent object to the API.
 * @param {Respondent} respondent - The revised respondent object to post to the API
 * @param {AbortController} controller - The abort controller to cancel requests
 * @return {Promise} - Returns the axios request promise.
 */
export async function respondentPutQuery(respondent, controller) {
    const requestOptions = {
        method: 'PUT',
        url: `/respondents/${respondent?.id}/`,
        data: respondent,
        signal: controller.signal
    };
    return api(requestOptions);
}