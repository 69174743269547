import { selector } from "recoil";
import { loginCodeAtom } from "_atoms";

/**
 * Return the id of the logged in respondent. String.
 */
const respondentIdSelector = selector({
    key: 'respondentId',
    get: ({get}) => {
        /** @type LoginCode */
        const loginCode = get(loginCodeAtom);
        /** @type string */
        return loginCode.respondent || '';
    }
});

export { respondentIdSelector };