import { useEffect } from "react";
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { assessmentIdAtom, progressionsAtom } from "_atoms";
import {
    batchIdSelector,
    currentStepIdSelector,
    hasStartedAssessmentSelector,
    hasStartedBatchSelector,
    hasStartedCurrentStepSelector,
    respondentIdSelector,
    startTimeOfCurrentStepSelector
} from "_selectors";
import { createProgression, mergeProgressions } from "_helpers/progression-helpers";

/**
* When there is a change in the startTime, check if we need to add a new progression recording the start.
*/
const useStartTime = () => {
    /** @type ?string */
    const assessmentId = useRecoilValue(assessmentIdAtom);
    /** @type ?string */
    const batchId = useRecoilValue(batchIdSelector);
    /** @type boolean */
    const hasStartedAssessment = useRecoilValue(hasStartedAssessmentSelector);
    /** @type boolean */
    const hasStartedBatch = useRecoilValue(hasStartedBatchSelector)
    /** @type boolean */
    const hasStartedCurrentStep = useRecoilValue(hasStartedCurrentStepSelector);
    /** @type ?string */
    const stepId = useRecoilValue(currentStepIdSelector);
    /** @type Dispatch<SetStateAction<Progression[]>> */
    const setProgressions = useSetRecoilState(progressionsAtom);
    /** @type ?string */
    const respondentId = useRecoilValue(respondentIdSelector);
    /** @type ?Date */
    const startTime = useRecoilValue(startTimeOfCurrentStepSelector);

    useEffect(() => {
        // Do nothing when (a) there is no start time to record, or (b) the step has already been started
        if (!startTime || hasStartedCurrentStep) return;

        /** @type {?string} - When the assessment is not yet started, record it as started */
        const assessmentBeingStarted = hasStartedAssessment ? null : assessmentId;
        /** @type {?string} - When the batch is not yet started, record it as started */
        const batchBeingStarted = hasStartedBatch ? null : batchId;
        /** @type {Progression} - Create a new progression object */
        const startProgression = createProgression(
            startTime, respondentId, stepId, 'S', assessmentBeingStarted, batchBeingStarted
        );

        // Update the progressions array
        setProgressions((prev) => mergeProgressions([startProgression], prev));
    }, [assessmentId, batchId, hasStartedAssessment, hasStartedBatch, hasStartedCurrentStep, respondentId, stepId,
        setProgressions, startTime]);
}

export { useStartTime };