import {
    before,
    countElements,
    findUnansweredElement,
    findLastAnsweredElement,
    getElementIds,
    getIdsOfAnsweredElements
} from "_helpers/reply-helpers"

/**
 * Check whether to display a backward nav link
 * @param {boolean} canReenterPreviousStep - The status of the canReenterAfterCompletion property of the previous step,
 *                                          or false when there is no previous step.
 * @param {number} indexOfFirstElementOnPage - The element index for which to determine whether to show a nav
 * @param {boolean} isBackwardNavigationAllowed - Whether the step allows respondents to revisit previous items
 * @return {boolean} - Returns whether the step has been completed
 */
export function checkForBackwardNav(canReenterPreviousStep, indexOfFirstElementOnPage, isBackwardNavigationAllowed){
    if (!isBackwardNavigationAllowed) return false;

    const isFirstElementOnStep = indexOfFirstElementOnPage === 0;
    return canReenterPreviousStep || !isFirstElementOnStep;
}

/**
 * Check whether to display a forward nav link
 * @param {boolean} hasJumpForwardNav - The list of ids for elements (items and fields) within a step
 * @param {boolean} isInstructionsPage - The elements on the page
 * @param {boolean} isSkippingAllowed - The id of the step for which the completion status is desired
 * @return {boolean}
 */
export function checkForForwardNav(hasJumpForwardNav, isInstructionsPage, isSkippingAllowed){
    /** @type {boolean} - Pages containing only instruction do not have a forward nav. They instead have a continue button. */
    if (isInstructionsPage) return false;

    return isSkippingAllowed || hasJumpForwardNav;
}

/**
 * Check whether to display a jump forward nav link
 * @param {number} indexOfLastAnsweredElement - The list of progression objects recording all starts and completions thus far
 * @param {number} indexOfLastElementOnCurrentPage - The id of the step for which the completion status is desired
 * @return {boolean} - Returns whether the step has been completed
 */
export function checkForJumpForwardNav(indexOfLastAnsweredElement, indexOfLastElementOnCurrentPage){
    return indexOfLastAnsweredElement >= indexOfLastElementOnCurrentPage;
}

/**
 * Check whether to display a jump to last nav link
 * @param {number} indexOfLastAnsweredElement - The list of progression objects recording all starts and completions thus far
 * @param {number} indexOfLastElementOnStep - The id of the step for which the completion status is desired
 * @return {boolean}
 */
export function checkForJumpToLastNav(indexOfLastAnsweredElement, indexOfLastElementOnStep){
    return indexOfLastAnsweredElement === indexOfLastElementOnStep;
}

/**
 * Check whether to display a jump backward nav link
 * @param {number} indexOfPriorUnansweredElement - Index of the first unanswered item below the elementIndex
 * @return {boolean} - Returns whether the step has been completed
 */
export function checkForJumpBackwardNav(indexOfPriorUnansweredElement){
    return indexOfPriorUnansweredElement > -1;
}

/**
 * Check whether a list of elements contains any non-instructions (i.e., contains any items or fields)
 * @param {(Instruction|Item|Field)[]} elements - The list of elements in which to search. The haystack.
 * @return {boolean}
 */
function detectItemsOrFields(elements) {
    /** @type {boolean} - Check whether any elements lack the body tag. Instructions have a body tag. Items and fields don't. */
    return elements.findIndex((element) => !('body' in element)) > -1
}

/**
 * Check which nav links should be displayed given a particular step, elementIndex, and set of answers and demographics
 * @param {Answer[]} answers - The list of answer objects
 * @param {boolean} canReenterPreviousStep - Status of the canReenterAfterCompletion property of the previous step
 * @param {Demographic[]} demographics - The list of demographic objects containing replies to fields
 * @param {(Instruction|Item|Field)[]} elementsOnPage - The list of elements being displayed
 * @param {number} endIndex - Index of the last element on the page
 * @param {number} startIndex - Index of the first element on the page
 * @param {Step} step - The step object defining materials administered
 * @return {[boolean, boolean, boolean, boolean, boolean]} - Returns an array of booleans
 */
export function determineNavLinks(answers, canReenterPreviousStep, demographics, elementsOnPage, endIndex, startIndex, step) {
    /** @type string[] */
    const elementIds = getElementIds(step);
    /** @type string[] */
    const idsOfAnsweredElements = getIdsOfAnsweredElements(answers, demographics);
    /** @type number */
    const indexOfLastElementOnStep = countElements(step) - 1;
    /** @type number */
    const indexOfLastAnsweredElement = findLastAnsweredElement(elementIds, idsOfAnsweredElements);
    /** @type number */
    const indexOfPriorUnansweredElement = findUnansweredElement(before, idsOfAnsweredElements, startIndex, step);
    /** @type boolean */
    const isInstructionsPage = !detectItemsOrFields(elementsOnPage);
    /** @type boolean */
    const hasJumpForwardNav = checkForJumpForwardNav(indexOfLastAnsweredElement, endIndex);
    /** @type boolean */
    const hasJumpBackwardNav = checkForJumpBackwardNav(indexOfPriorUnansweredElement);
    /** @type boolean */
    const hasBackwardNav = checkForBackwardNav(canReenterPreviousStep, startIndex, !!step?.isBackwardNavigationAllowed);
    /** @type boolean */
    const hasForwardNav = checkForForwardNav(hasJumpForwardNav, isInstructionsPage, !!step?.isSkippingAllowed);
    /** @type boolean */
    const hasJumpToLastNav = checkForJumpToLastNav(indexOfLastAnsweredElement, indexOfLastElementOnStep);

    return [hasBackwardNav, hasForwardNav, hasJumpBackwardNav, hasJumpForwardNav, hasJumpToLastNav];
}

/**
 * Find the index of the last element in a step
 * @param {Step} step - The step for which to retrieve the index of the last element
 * @return {number}
 */
export function findIndexOfLastElement(step) {
    const numberOfInstructions = step?.instructions?.length || 0;
    const numberOfItems = step?.items?.length || 0;
    const numberOfFields = step?.fields?.length || 0;
    return numberOfInstructions + numberOfItems + numberOfFields - 1;
}