import React from "react";
import PropTypes from "prop-types";
import Button from 'react-bootstrap/Button';
import './LoginCodeShowButton.css';

const LoginCodeShowButton = ({ onClick }) => {
    return (
        <Button variant="outline-secondary" onClick={onClick} className="login-code-show-button">
            Show my login code
        </Button>
    )
}

LoginCodeShowButton.propTypes = {
    onClick: PropTypes.func.isRequired
};


export default LoginCodeShowButton;