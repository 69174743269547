import { atom } from "recoil";

/**
 * A list of Answer objects indicating the respondent's responses to each of the Items.
 */
const answersAtom = atom({
  key: 'answers',
  default: []
});

export { answersAtom };