import { api } from "./query-interceptors";

/**
 * Retrieve info associated with a previously existing login
 * @param {string} respondentId - The respondent for whom to retrieve scores
 * @param {AbortController} controller - The abort controller to cancel requests
 * @return {Promise} - Returns the axios request promise.
 */
export function scoresGetQuery(respondentId, controller) {
    const requestOptions = {
        method: 'GET',
        url: `/scores/?respondent=${respondentId}`,
        signal: controller.signal
    };
    return api(requestOptions);
}