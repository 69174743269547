import { useEffect } from "react";
import { useRecoilValue, useResetRecoilState } from "recoil";
import { assessmentIdAtom, elementIndexAtom, stepIndexAtom } from "_atoms";
import { currentStepIdSelector, hasCompletedAllMaterialsSelector } from "_selectors";

/**
 * When no step id is found for the current step index, reset the assessment id, element index, and step index.
 */
const useStepId = () => {
    /** @type ?string */
    const currentStepId = useRecoilValue(currentStepIdSelector);
    /** @type boolean */
    const hasCompletedAllMaterials = useRecoilValue(hasCompletedAllMaterialsSelector);
    /** @type function */
    const resetAssessmentId = useResetRecoilState(assessmentIdAtom);
    /** @type function */
    const resetElementIndex = useResetRecoilState(elementIndexAtom);
    /** @type function */
    const resetStepIndex = useResetRecoilState(stepIndexAtom);

    useEffect(() => {
        // So long as there is an active current step, don't need to do anything.
        if (!!currentStepId) return;

        resetAssessmentId();
        resetElementIndex();
        resetStepIndex();
    }, [currentStepId, hasCompletedAllMaterials, resetAssessmentId, resetElementIndex,
        resetStepIndex]);
};

export { useStepId };