import { atom } from 'recoil';

/**
 * The language in which the respondent chooses to complete materials.
 */
const languageAtom = atom({
    key: 'language',
    default: ''
});

export { languageAtom };