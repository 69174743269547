import { selector } from "recoil";
import { respondentAtom } from "_atoms";
import { hasFinishedBatchSelector, progressionsReadyToCheckSelector } from "_selectors";
import { scoresGetQuery } from "_queries/scores-queries";

/**
 * A respondent's scores
 */
const scoresSelector = selector({
    key: 'scores',
    get: async ({get}) => {
        /** @type Respondent */
        const respondent = get(respondentAtom);
        /** @type Progression[] */
        const progressionsReadyToCheck = get(progressionsReadyToCheckSelector);
        /** @type boolean */
        const hasFinishedBatch = get(hasFinishedBatchSelector);
        /** @type AbortController */
        const controller = new AbortController();

        // No need to retrieve scores when there are no relevant progressions prior to final completion
        if (progressionsReadyToCheck.length === 0 && !hasFinishedBatch) {
            return [];
        }

        /** @type Promise */
        return scoresGetQuery(respondent?.id, controller).then((axiosResponse) => {
            return axiosResponse?.data?.results;
        }).catch((axiosError) => {
            const message = axiosError?.response?.data?.message || axiosError?.response?.statusText || axiosError.message;
            throw new Error(message);
        });
    }
});

export { scoresSelector };