import { selector } from "recoil";
import { progressionsAtom } from "_atoms";
import { batchSelector, scoresSelector } from "_selectors";
import {
    checkForMatchingProgression,
    checkIfAllThresholdsHaveScores,
    runCarelessResponseCheck
} from "_helpers/careless-response-check-helpers"

/**
 * Return the list of careless response check objects for the batch.
 */
const carelessResponseChecksSelector = selector({
    key: 'carelessResponseChecks',
    get: ({get}) => {
        /** @type Batch|T */
        const batch = get(batchSelector);

        /** @type CarelessResponseCheck[] */
        return batch?.carelessResponseChecks || [];
    }
});

/**
 * Return the list of progressions that should be ready to run careless response checks
 */
const progressionsReadyToCheckSelector = selector({
    key: 'progressionsReadyToCheck',
    get: ({get}) => {
        /** @type CarelessResponseCheck[] */
        const carelessResponseChecks = get(carelessResponseChecksSelector);
        /** @type Progression[] */
        const progressions = get(progressionsAtom);

        return progressions.filter((progression) =>
            carelessResponseChecks.findIndex((check) => check.calculateAfterStep === progression.step) > -1 &&
            !progression?.deletedAt &&
            !progression?.ignoredAt &&
            progression?.isSaved === true &&
            progression?.type === 'C'
        );
    }
});

/**
 * Return the list of careless response checks that are ready to run. A careless response check is ready to run when
 * (1) All thresholds have score objects, and (2) the careless response check has a matching completion progression
 * that isn't flagged (neither flagged as deleted nor ignored).
 */
const carelessResponseChecksToRunSelector = selector({
    key: 'carelessResponseChecksToRun',
    get: ({get}) => {
        /** @type CarelessResponseCheck[] */
        const carelessResponseChecks = get(carelessResponseChecksSelector);
        /** @type Progression[] */
        const progressionsReadyToCheck = get(progressionsReadyToCheckSelector);
        /** @type Score[] */
        const scores = get(scoresSelector);

        // Can exit when there are no careless response checks or no scores
        if (!carelessResponseChecks?.length || !progressionsReadyToCheck?.length || !scores?.length) return [];

        return carelessResponseChecks.filter((carelessResponseCheck) =>
            checkIfAllThresholdsHaveScores(scores, carelessResponseCheck?.thresholds)
        ).filter((carelessResponseCheck) =>
            checkForMatchingProgression(carelessResponseCheck, progressionsReadyToCheck)
        );
    }
});

/**
 * The list of careless response checks that failed validation.
 */
const failedCarelessResponseChecksSelector = selector({
    key: 'failedCarelessResponseChecks',
    get: ({get}) => {
        /** @type CarelessResponseCheck[] */
        const carelessResponseChecks = get(carelessResponseChecksToRunSelector);
        /** @type Score[] */
        const scores = get(scoresSelector);
        
        return carelessResponseChecks.filter((carelessResponseCheck) =>
            !runCarelessResponseCheck(carelessResponseCheck, scores)
        );
    }
});

/**
 * Count the number of failed careless response checks
 */
const numberOfFailedCarelessResponseChecksSelector = selector({
    key: 'numberOfFailedCarelessResponseChecks',
    get: ({get}) => {
        /** @type CarelessResponseCheck[] */
        const failedCarelessResponseChecks = get(failedCarelessResponseChecksSelector);

        return failedCarelessResponseChecks?.length || 0;
    }
});

export {
    carelessResponseChecksSelector,
    carelessResponseChecksToRunSelector,
    failedCarelessResponseChecksSelector,
    numberOfFailedCarelessResponseChecksSelector,
    progressionsReadyToCheckSelector
};