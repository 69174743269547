import React from "react";
import { useRecoilState } from "recoil";
import Alert from "react-bootstrap/Alert";
import { loginStatusAtom } from "_atoms";
import { checkForErrorMessage } from "_helpers/login-helpers";

/**
* An alert box for displaying a login-related error message returned from the API
*/
const LoginAlert = () => {
    /** @type [LoginStatus, Dispatch<SetStateAction<LoginStatus>>] */
    const [loginStatus, setLoginStatus] = useRecoilState(loginStatusAtom);
    /** @type boolean */
    const hasError = checkForErrorMessage(loginStatus);
    /** @type function */
    const clearErrorMessage = () => {
        setLoginStatus((prev) => (
            /** @type {LoginStatus|any} */
            {...prev, errorMessage: ''}
        ))
    }

    if (!hasError) return;

    return (
        <Alert variant="danger" onClose={clearErrorMessage} dismissible className="login-alert mt-3">
            {loginStatus.errorMessage}
        </Alert>
    );
}

export default LoginAlert;