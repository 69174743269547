import {v4 as createUuid} from "uuid";

/**
 * Check if a new progression object already has a matching one (i.e., same respondent, type, and step) in a list of progressions.
 * @param {Progression} progression - The progression for which to search for a match
 * @param {Progression[]} progressions - The list of progressions in which to search for a match
 * @return {boolean} - Returns true when the list already contains a progression with the same respondent, type, and step
 */
function checkForDuplicateProgression(progression, progressions) {
    return progressions.findIndex((original) =>
        original.respondent === progression.respondent &&
        original.step === progression.step &&
        original.type === progression.type &&
        original.deletedAt === progression.deletedAt
    ) > -1;
}

/**
 * Create new progression object given the properties for the new object.
 * @param {Date} createdAt - The timestamp to record for the new progression
 * @param {string} respondentId - The id of respondent completing materials
 * @param {?string} stepId - The id of the step being started or completed
 * @param {"S"|"C"} type - One of 'S' for started or 'C' for completed
 * @param {?string} assessmentId - When the assessment is also being started or completed, the ID of the assessment
 * @param {?string} batchId - When starting the first material of a batch, the batch being started. When the respondent
 *      indicates they are finished responding, the batch completed.
 * @return {Progression} - Returns the newly created progression object
 */
export function createProgression(createdAt, respondentId, stepId, type, assessmentId, batchId) {
    return (
        /** @type Progression */
        {
            id: createUuid().toString(),
            assessment: assessmentId,
            batch: batchId,
            createdAt: createdAt.toISOString(),
            deletedAt: null,
            ignoredAt: null,
            isSaved: false,
            respondent: respondentId,
            step: stepId,
            type: type
        }
    )
}

/**
 * Create a new list of progressions with certain ones removed. Matches are performed exclusively on the ID property.
 * @param {Progression[]} allProgressions - The complete list of progressions being filtered
 * @param {Progression[]} progressionsToExclude - The list of progressions to exclude from the returned list.
 * @return {Progression[]} - Returns a filtered list of progressions with the newlySavedProgressions left out.
 */
function excludeProgressions(allProgressions, progressionsToExclude) {
    return allProgressions.filter(({id: id1}) => !progressionsToExclude.some(({id: id2}) => id2 === id1));
}

/**
 * Fetch a list of progression objects that have not yet been marked as saved (i.e., their isSaved property is false)
 * @param {Progression[]} progressions - The list of progressions in which to search for unsaved ones
 * @return {Progression[]} - Returns a list of the unsaved progressions
 */
export function getUnsavedProgressions(progressions) {
    return progressions.filter((progression) => progression?.isSaved === false);
}

/**
 * Merge two lists of progressions, adding newProgressions to a list of existing ones. When an entry in newProgressions
 * array already has a match in the oldProgressions (same respondent, step, and type), the one from the original
 * oldProgressions array will be retained instead of the new one.
 * @param {Progression[]} newProgressions - The new progressions to append
 * @param {Progression[]} oldProgressions - The existing list of progressions being appended
 * @return {Progression[]} - Returns a new list of progressions with the additional progressions appended. If there
 *                          was already a matching progression (same respondent, step, and type), returns the
 *                          original list of progressions.
 */
export function mergeProgressions(newProgressions, oldProgressions) {
    const uniqueNewProgressions = newProgressions.filter((progression) =>
        !checkForDuplicateProgression(progression, oldProgressions)
    );
    return [...oldProgressions, ...uniqueNewProgressions];
}

/**
 * Copy a list of progressions but append an isSaved=true property on all progressions objects in the list.
 * @param {Progression[]} progressions - The list of progressions for which to append an isSaved=true property
 * @return {Progression[]} - Returns a new list of progressions in which each progression has an isSaved=true property
 */
function setProgressionStatusToSaved(progressions) {
    return progressions.map((progression) => (
        /** @type Progression */
        {...progression, isSaved: true}
    ));
}

/**
 * After progressions are posted to the database, create a revised list of progressions suitable for saving in the app
 * state with the isSaved property set to true for all the newly added ones.
 * @param {Progression[]} newlySavedProgressions - The list of newly-saved progressions returned by the API
 * @param {Progression[]} allProgressions - The original list of progressions prior to posting anything to the API
 * @return {Progression[]} - Returns the new list of progressions to be saved to the app state
 */
export function createRevisedListOfProgressionsAfterSave(allProgressions, newlySavedProgressions) {
    const unaffectedProgressions = excludeProgressions(allProgressions, newlySavedProgressions);
    const savedProgressions = setProgressionStatusToSaved(newlySavedProgressions);
    return [...unaffectedProgressions, ...savedProgressions];
}