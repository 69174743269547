import { atom } from "recoil";

/**
 * A respondent object containing their id, batch info, random seed, and unique login code value.
 */
const respondentAtom = atom({
  key: 'respondent',
  default:
  /** @type Respondent */
  {
    id: '',
    batch: null,
    completedAt: null,
    continent: '',
    country: '',
    createdAt: null,
    isSaved: true,
    loginCodeValue: '',
    randomSeed: 0,
    startedAt: null,
    user: ''
  }
});

export { respondentAtom };