import { api } from "./query-interceptors";

/**
 * Retrieve information (including welcome message, list of materials, and debrief message) for a given batch
 * @param {string} batchId - The id of the batch to retrieve
 * @param {AbortController} controller - The abort controller to cancel requests
 * @return {Promise} - Returns the axios request promise.
 */
export async function batchGetQuery( batchId, controller) {
    const requestOptions = {
        method: 'GET',
        url: `/batches/${batchId}/`,
        params: {
            expand: 'carelessResponseChecks,debrief,materials,welcome'
        },
        signal: controller.signal
    };
    return api(requestOptions);
}