/**
 * The word "reply" is used to refer to either (1) the Answer to an Item, or (2) the Demographic response to a Field.
 * Both answers and demographics use the same helper functions.
 */

/**
 * Verify that a number is above the given threshold value
 * @param {number} number - Number to compare to the threshold
 * @param {number} threshold - The value that the number must be higher than
 * @return {boolean}
 */
export function after(number, threshold) {
    return number > threshold;
}

/**
 * Verify that a number is below the given threshold value
 * @param {number} number - Number to compare to the threshold
 * @param {number} threshold - The value that the number must be lower than
 * @return {boolean}
 */
export function before(number, threshold) {
    return number < threshold;
}

/**
 * Compare two replies to check if they are identical. Identical means (1) are for the same item/field, (2) have the
 * same value, and (3) have the same selected groups. Differences in reply ids and timestamps are ignored.
 * @param {Answer|Demographic} newReply - The current reply (an answer or a demographic)
 * @param {?(Answer|Demographic)} previousReply - The previous reply to the same item or field
 * @param {"field"|"item"} elementType - Whether the reply is to a Field or an Item
 * @param {"groups"|"options"} listType - Whether the list elements are Groups (in demographics) or Options (in answers)
 * @return {boolean} - Returns true when the demographics are identical, or false if there is a difference.
 */
export function checkIfReplyIsIdentical(newReply, previousReply, elementType, listType) {
    /** @type boolean */
    const isSameElementType = newReply?.[elementType] === previousReply?.[elementType];
    /** @type boolean */
    const isValueIdentical = previousReply?.value === newReply?.value;
    /** @type boolean */
    const isFileIdentical = previousReply?.file === newReply?.file;
    /** @type boolean */
    const isSameNumberOfListElementsSelected = previousReply?.[listType]?.length === newReply?.[listType]?.length;
    /** @type boolean */
    const areListElementsInSameOrder = previousReply?.[listType]?.every((value, index) => value === newReply?.[listType]?.[index]);
    /** @type boolean */
    const isPreviousReplyDeleted = !!previousReply?.deletedAt;

    return (
        isSameElementType &&
        isValueIdentical &&
        isFileIdentical &&
        isSameNumberOfListElementsSelected &&
        areListElementsInSameOrder &&
        !isPreviousReplyDeleted
    );
}

/**
 * Determine if any of the unsaved replies come from a different step than the current one
 * @param {(Answer|Demographic)[]} unsaved - The list of yet-to-be-saved replies (answers or demographics)
 * @param {string[]} idsInCurrentStep - A list of the ids of all the elements (items & fields) in the current step
 * @param {"item"|"field"} itemOrField - Whether the element to check for is a Field or an Item
 * @return {boolean} - Returns a boolean indicating whether to trigger a save due to the number of unsaved answers
 */
export function checkForUnsavedRepliesFromDifferentStep(unsaved, idsInCurrentStep, itemOrField) {
    return unsaved.findIndex((element) => !idsInCurrentStep.includes(element?.[itemOrField])) > -1;
}

/**
 * Determine if the maximum seconds between saves threshold has been met
 * @param {Integer} maxSecondsBetweenSaves - The maximum number of seconds to elapse between save attempts
 * @param {Date} currentTime - The current time
 * @param {?Date} dateOfFirstUnsavedReply - The date of the earliest reply (answer or demographic) that has not yet
 *                                          been saved, if there is one
 * @return {boolean} - Returns a boolean indicating whether to trigger a save due to the elapsed time
 */
export function checkMaxSecondsBetweenSaves(maxSecondsBetweenSaves, currentTime, dateOfFirstUnsavedReply) {
    // When max seconds between saves is set to 0, always return false. Time-based saves are disabled.
    // Likewise, when there is no first unsaved reply date, there's nothing to save.
    if (!maxSecondsBetweenSaves || !dateOfFirstUnsavedReply) return false;

    const secondsSinceFirstUnsavedReply = (currentTime - dateOfFirstUnsavedReply) / 1000;
    return secondsSinceFirstUnsavedReply >= maxSecondsBetweenSaves;
}

/**
 * After answers are posted to the database, create a revised list of answers suitable for saving in the app state
 * with the isSaved property set to true for all the newly added ones.
 * @param {(Answer|Demographic)[]} newlySavedReplies - The list of newly saved answers or demographics returned from the API.
 * @param {(Answer|Demographic)[]} allSavedReplies - The current list of answers or demographics in application memory
 * @return {(Answer|Demographic)[]} - Returns a new list of all the answers or demographics in application memory, but
 *                                   where the newly saved ones have been replaced with ones in which the isSaved
 *                                   property is true rather than false.
 */
export function createRevisedListAfterSave(newlySavedReplies, allSavedReplies) {
    const unaffectedElements = excludeNewlySavedReplies(newlySavedReplies, allSavedReplies);
    const elementsToAdd = setStatusToSaved(newlySavedReplies);
    return [...unaffectedElements, ...elementsToAdd];
}

/**
 * Count the total number of elements (instructions, items, and fields) on a step
 * @param {?Step} step - The step for which to count the elements
 * @return number
 */
export function countElements(step) {
    const numberOfInstructions = step?.instructions?.length || 0;
    const numberOfItems = step?.items?.length || 0;
    const numberOfFields = step?.fields?.length || 0;

    return numberOfInstructions + numberOfItems + numberOfFields;
}

/**
 * Create a new list of answers with newly saved ones removed. Matches are performed exclusively on the ID property.
 * @param {(Answer|Demographic)[]} newlySavedReplies - The list of answers to exclude from the returned list.
 * @param {(Answer|Demographic)[]} allReplies - The complete list of answers being filtered
 * @return {(Answer|Demographic)[]} - Returns a filtered list of answers or demographics with the newly saved ones left out.
 */
function excludeNewlySavedReplies(newlySavedReplies, allReplies) {
    return allReplies.filter((reply) =>
        !newlySavedReplies.some((newlySavedReply) => reply.id === newlySavedReply.id)
    );
}

/**
 * Find the position of the first unanswered element (item or field) within a list of elements
 * @param {function} comparisonFunction - Function to compare an element's position to the elementIndex. One of
 *                                        above or below.
 * @param {string[]} idsOfAnsweredElements - Ids of the items that have answers and fields that have demographics
 * @param {number} elementIndex - Where to start the search for an unanswered element
 * @param {Step} step - The step in which to search for the unanswered item or field
 * @return {number} - Returns the index of the item that satisfies the search criteria
 */
export function findUnansweredElement(comparisonFunction, idsOfAnsweredElements, elementIndex, step) {
    /** @type string[] */
    const itemsIds = step?.items?.map((item) => item.id) || [];
    /** @type string[] */
    const fieldIds = step?.fields?.map((field) => field.id) || [];
    /** @type string[] */
    const elementIds = [...itemsIds, ...fieldIds];
    /** @type number */
    const numberOfInstructions = step?.instructions?.length || 0;
    /** @type {number} - Adjust the index to use in comparisons by the number of instructions */
    const comparisonIndex = elementIndex - numberOfInstructions;

    /** @type {number} - Within the array of items or fields, return the index of the first one without a reply */
    const indexOfFirstUnansweredItemOrField = elementIds.findIndex((elementId, index) =>
        comparisonFunction(index, comparisonIndex)
        && !idsOfAnsweredElements.some((answeredElementId) => elementId === answeredElementId)
    );

    /** @type {number} - Items & fields come after instructions, so their index is offset by the number of instructions */
    return indexOfFirstUnansweredItemOrField === -1 ? -1 : numberOfInstructions + indexOfFirstUnansweredItemOrField;
}

/**
 * Find the position of the last element (item or field) that has a reply (answer or demographic)
 * @param {string[]} elementIds - The ids of elements (instructions, items and fields) in the step in their order of administration
 * @param {string[]} idsOfAnsweredElements - Ids of the items that have answers and fields that have demographics
 * @return {number} - Returns the index of the item that satisfies the search criteria
 */
export function findLastAnsweredElement(elementIds, idsOfAnsweredElements) {
    // When there aren't any items or fields, return an index of -1 (no answered elements found).
    if (!elementIds?.length) return -1;

    /** @type {string[]} - Reverse the order of the element ids*/
    const reversed = [...elementIds].reverse();

    /** @type {number} - Find the index of the first answered element in the reversed array */
    const index = reversed.findIndex((elementId) =>
        idsOfAnsweredElements.some((answeredElementId) => elementId === answeredElementId)
    );

    //When no element is answered, return -1
    if (index === -1) return -1;

    //When an element is answered, un-reverse the index by subtracting the index from the maxElementIndex
    /** @type {number} */
    const maxElementIndex = elementIds.length - 1;

    return maxElementIndex - index;
}

/**
 * Retrieve the most recent non-deleted answer for a given item from a list of answers.
 * @param {string} elementId - The id of the element (item or field) for which to retrieve the most recent reply
 * @param {(Answer|Demographic)[]} replies - The list of replies in which to search for the most recent one
 * @param {"field"|"item"} elementType - Whether the reply is to a field or an item
 * @return {?(Answer|Demographic)} - Returns the most recent reply (answer or demographic) to the given element if there
 *                                  is one, or null if the element does not yet have a matching reply
 */
export function findMostRecentReplyForElement(elementId, replies, elementType) {
    const matchingReplies = replies.filter((reply) => reply?.[elementType] === elementId && !reply?.deletedAt);
    matchingReplies.sort((a, b) => new Date(b?.createdAt) - new Date(a?.createdAt));
    return matchingReplies?.[0] || null;
}

/**
 * Produce a list of all elements (items and fields) in a step in their order of administration
 * @param {Step} step - The step for which to create a list of element ids
 * @return {string[]} - Returns a list of the ids of all items and fields in the step
 */
export function getElementIds(step) {
    /** @type {string[]} - List of ids for items in the step */
    const instructionIds = step?.instructions?.map((item) => item.id) || [];
    /** @type {string[]} - List of ids for items in the step */
    const itemIds = step?.items?.map((item) => item.id) || [];
    /** @type {string[]} - List of ids for fields in the step */
    const fieldIds = step?.fields?.map((field) => field.id) || [];
    /** @type {string[]} - Concatenated list of item ids and field ids */
    return [...instructionIds, ...itemIds, ...fieldIds];
}

/**
 * Find the position of the first unanswered item within a list of items
 * @param {Answer[]} answers - The list of answers indicating which items have been answered
 * @param {Demographic[]} demographics - The list of demographics indicating which fields have a reply
 * @return {string[]} - Returns a list of the ids of the answered items and answered fields
 */
export function getIdsOfAnsweredElements(answers, demographics) {
    const idsOfAnsweredItems = answers.filter((answer) => !answer.deletedAt).map((answer) => answer.item);
    const idsOfAnsweredFields = demographics.filter((d) => !d.deletedAt).map((d) => d.field);
    const uniqueItemIds = new Set(idsOfAnsweredItems);
    const uniqueFieldIds = new Set(idsOfAnsweredFields);
    return [...uniqueItemIds, ...uniqueFieldIds];
}

/**
 * Find the date of the earliest element (answer or demographic) that has not yet been saved (i.e., has isSaved property
 * set to false)
 * @param {Answer[]|Demographic[]} replies - The current list of replies (answers or demographics)
 * @return {?Date} - Returns the date of the earliest unsaved reply, or null when there is no unsaved reply
 */
export function getDateOfFirstUnsavedReply(replies) {
    // Produce a list of just the unsaved answers
    const unsavedReplies = replies.filter((element) => !element?.isSaved);

    // Sort the unsaved answers in ascending order by their createdAt property, returning the first one
    const firstUnsavedReply = unsavedReplies.sort(
        (a, b) => new Date(a?.createdAt) - new Date(b?.createdAt)
    ).shift();

    // When there's a first unsaved reply, return the date it was created. Otherwise, return null.
    return firstUnsavedReply ? new Date(firstUnsavedReply?.createdAt) : null;
}

/**
 * Retrieve all the unsaved replies (answers or demographics) based on the isSaved property of the objects.
 * @param {(Answer|Demographic)[]} replies - The list of replies in which to search for unsaved ones
 * @return {(Answer|Demographic)[]} - Returns a list of the unsaved replies
 */
export function getUnsavedReplies(replies) {
    return replies.filter(reply => reply?.isSaved === false && !reply?.file);
}

/**
 * Copy a list of answers but append an isSaved=true property on all answer objects in the list.
 * @param {(Answer|Demographic)[]} elements - The list of answers of demographics for which to append an isSaved=true property
 * @return {(Answer|Demographic)[]} - Returns a new list of answers or demographics in which each element has an isSaved=true property
 */
function setStatusToSaved(elements) {
    return elements.map((element) => (
        /** @type Answer|Demographic*/
        {...element, isSaved: true}
    ));
}