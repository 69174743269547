import React from "react";
import Form from 'react-bootstrap/Form';

const LanguageSelectBox = ({ languages, onChange, value }) => {
    /** @type JSX.Element[] */
    const options = languages.map((language, index) =>
        <option key={index} value={language.id} label={language.localName}/>
    );

    return (
        <Form.Select onChange={onChange} className="form-select-lg" value={value}>
            {options}
        </Form.Select>
    );
}

export default LanguageSelectBox;