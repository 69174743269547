/**
* Generate the textual value to show as time remaining. When there is more than an hour remaining, uses the format
* h:mm:ss. When there are only minutes or seconds remaining, uses the format m:ss.
* @param {Date} currentTime - The current date and time
* @param {Date} endTime - The date by which the step must be completed
* @return {string} - The amount of time remaining to complete the step displayed as either h:mm:ss or mm:ss.
*/
export function createTimeRemainingText(currentTime, endTime) {
    // When the deadline has already passed, return '0:00'
    if (currentTime > endTime) return '0:00';

    // Calculate the number of seconds remaining between the current and end times
    const diffInSeconds = Math.abs(endTime - currentTime) / 1000;

    // Find the hours, minutes, and seconds values that correspond to that many seconds remaining
    const hours = Math.floor(diffInSeconds / 60 / 60 % 24);
    const minutes = Math.floor(diffInSeconds / 60 % 60);
    const seconds = Math.floor(diffInSeconds % 60);

    // Add a leading zero in front of the minutes and seconds values if they only contain one digit.
    const zeroPaddedSeconds = ('0' + seconds).slice(-2);
    const zeroPaddedMinutes = ('0' + minutes).slice(-2);

    // When there is more than an hour remaining, return h:mm:ss. Otherwise, return m:ss.
    return hours > 0 ? `${hours}:${zeroPaddedMinutes}:${zeroPaddedSeconds}` : `${minutes}:${zeroPaddedSeconds}`;
}