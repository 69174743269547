import axios from "axios";
import i18next from 'i18next';
import { memoizedRefreshedSession } from "./refresh-token-queries";
import { constants } from '../constants';

const axiosApiInstance = axios.create({
    baseURL: constants.apiUrl,
    headers: {
        Accept: "application/json"
    },
    responseType: 'json',
    responseEncoding: 'utf8',
    timeout: 20000,
    withCredentials: false
});

axiosApiInstance.interceptors.request.use(
    async (config) => {
        /** @type {Session} - The session stored in session storage */
        const session = JSON.parse(sessionStorage.getItem("session"));

        // If there is an access token in the session, add it to the constants headers
        if (session?.accessToken) {
            config.headers = {
                ...config.headers,
                authorization: `Bearer ${session?.accessToken}`
            };
        }

        // Add i18next language information to the accept headers
        config.headers = {
            ...config.headers,
            "Accept-Language": i18next.language
        };

        return config;
    },
  (error) => Promise.reject(error)
);

axiosApiInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
        const config = error?.config;

        /**
         * Reject the promise if it's not a forbidden status, or the request has already been sent a 2nd time,
         * or there weren't any authorization headers sent in the initial request
         */
        if (error?.response?.status !== 401 || config?.sent === true || !config?.headers?.authorization) {
            return Promise.reject(error);
        }

        // Record that the request has been sent already and obtain the refreshed session information
        config.sent = true;
        const refreshedSession = await memoizedRefreshedSession();

        // If the refreshed session lacks an access token, just reject the promise
        if (!refreshedSession?.accessToken) {
            return Promise.reject(error);
        }

        // Update the constants headers to include the newly refreshed access token, and try the query again
        config.headers = {
            ...config.headers,
            authorization: `Bearer ${refreshedSession?.accessToken}`,
        };

        return axios(config);
    }
);

export const api = axiosApiInstance;