import { selector } from "recoil";
import { assessmentIdAtom, progressionsAtom, respondentAtom } from "_atoms";
import { assessmentGetQuery } from "_queries/assessment-queries";
import {
    applyRandomSeedToAssessment,
    findIndexOfFirstUncompletedStep,
} from "_helpers/assessment-helpers";
import { Controller } from "react-bootstrap-icons";

/**
 * When there is an active assessment id or a single assessment being administered in the batch, fetch the corresponding
 * assessment information from the API.
 */
const assessmentSelector = selector({
    key: "assessment",
    get: async ({ get }) => /** @type ?Assessment */ {
        /** @type string */
        const assessmentId = get(assessmentIdAtom);
        /** @type Respondent */
        const respondent = get(respondentAtom);
        /** @type AbortController */
        const controller = new AbortController();

        localStorage.setItem("respondent", JSON.stringify(respondent));

        if (!assessmentId || !respondent?.id) return null;

        /** @type Promise */
        return assessmentGetQuery(assessmentId, controller)
            .then((axiosResponse) => {
                /** @type {Assessment} - Apply the random seed to item orders for any steps that have isRandomized = true */
                return applyRandomSeedToAssessment(
                    axiosResponse.data,
                    respondent?.randomSeed
                );
            })
            .catch((axiosError) => {
                const message =
                    axiosError?.response?.data?.message ||
                    axiosError?.response?.statusText ||
                    axiosError.message;
                throw new Error(message);
            });
    },
});

/**
 * Determine if the respondent has a progression marking the assessment as started. Returns boolean.
 */
const hasStartedAssessmentSelector = selector({
    key: "hasStartedAssessment",
    get: ({ get }) => {
        /** @type Assessment */
        const assessment = get(assessmentSelector);
        /** @type Progression[] */
        const progressions = get(progressionsAtom);

        // When there is no assessment info or no progressions, the assessment cannot possibly be started
        if (!assessment?.id || !progressions?.length) return false;

        /** @type {boolean} - Return true when the assessment has a non-deleted start progression */
        return (
            progressions.findIndex(
                (progression) =>
                    progression?.assessment === assessment?.id &&
                    progression?.type === "S" &&
                    !progression?.deletedAt
            ) > -1
        );
    },
});

/**
 * Find the index of the first step on the assessment that lacks a completion progression. Returns an integer.
 */
const indexOfFirstUncompletedStepSelector = selector({
    key: "indexOfFirstUncompletedStep",
    get: ({ get }) => {
        /** @type Assessment */
        const assessment = get(assessmentSelector);
        /** @type Progression[] */
        const progressions = get(progressionsAtom);

        return findIndexOfFirstUncompletedStep(assessment, progressions);
    },
});

export {
    assessmentSelector,
    hasStartedAssessmentSelector,
    indexOfFirstUncompletedStepSelector,
};
