import { selector } from "recoil";
import { answersAtom, demographicsAtom, elementIndexAtom, progressionsAtom, stepIndexAtom } from "_atoms";
import { assessmentSelector, pageSelector } from "_selectors";
import { checkIfTimerIsPaused } from "_helpers/page-helpers";
import {after, before, countElements, findUnansweredElement, getIdsOfAnsweredElements} from "_helpers/reply-helpers";
import { findIndexOfLastElement } from "_helpers/nav-helpers";

/**
 * Return the current Step object based on the value of the assessment selector and stepIndex atom.
 */
const currentStepSelector = selector({
    key: 'currentStep',
    get: ({get}) => {
        /** @type Assessment|T */
        const assessment = get(assessmentSelector);
        /** @type Index */
        const stepIndex = get(stepIndexAtom);

        /** @type ?Step */
        return assessment?.steps?.[stepIndex?.value] || null;
    }
});

/**
 * Return the id of the current step.
 */
const currentStepIdSelector = selector({
    key: 'currentStepId',
    get: ({get}) => {
        /** @type Step */
        const currentStep = get(currentStepSelector);

        /** @type ?string */
        return currentStep?.id || null;
    }
});

/**
 * Return the maximum element index of the current step.
 */
const currentStepMaxElementIndexSelector = selector({
    key: 'currentStepMaxElementIndex',
    get: ({get}) => {
        /** @type ?Step */
        const currentStep = get(currentStepSelector);
        /** @type number */
        const elementCount = countElements(currentStep);

        /** @type number */
        return elementCount - 1;
    }
});

/**
 * Check if the current step has a non-deleted start progression
 */
const hasStartedCurrentStepSelector = selector({
    key: 'isStarted',
    get: ({get}) => {
        /** @type ?string */
        const currentStepId = get(currentStepIdSelector);
        /** @type Progression[] */
        const progressions = get(progressionsAtom);

        if (!currentStepId || !progressions?.length) return false;

        /** @type {boolean} - Check if the current step has a non-deleted start progression. */
        return progressions.findIndex((progression) =>
            progression.step === currentStepId &&
            progression.type === 'S' &&
            !progression.deletedAt
        ) > -1;
    }
});

/**
 * Return the index of the next unanswered element on the current step (after the current page)
 */
const indexOfNextUnansweredElementSelector = selector({
    key: 'indexOfNextUnansweredElement',
    get: ({get}) => {
        /** @type Step */
        const currentStep = get(currentStepSelector);
        /** @type Answer[] */
        const answers = get(answersAtom);
        /** @type Demographic[] */
        const demographics = get(demographicsAtom);
        /** @type Page */
        const page = get(pageSelector);

        const idsOfAnsweredElements = getIdsOfAnsweredElements(answers, demographics);
        return findUnansweredElement(after, idsOfAnsweredElements, page?.endIndex, currentStep);
    }
});

/**
 * Return the index of the previous unanswered element on the current step (before the current page)
 */
const indexOfPreviousUnansweredElementSelector = selector({
    key: 'indexOfPreviousUnansweredElement',
    get: ({get}) => {
        /** @type Step */
        const currentStep = get(currentStepSelector);
        /** @type Answer[] */
        const answers = get(answersAtom);
        /** @type Demographic[] */
        const demographics = get(demographicsAtom);
        /** @type Page */
        const page = get(pageSelector);
        /** @type string[] */
        const idsOfAnsweredElements = getIdsOfAnsweredElements(answers, demographics);

        return findUnansweredElement(before, idsOfAnsweredElements, page?.startIndex, currentStep);
    }
});

/**
 * Return the index of the last element in the current step
 */
const indexOfLastElementSelector = selector({
    key: 'indexOfLastElement',
    get: ({get}) => {
        /** @type Step */
        const currentStep = get(currentStepSelector);

        return findIndexOfLastElement(currentStep);
    }
});

/**
 * Check if the timer for the current step is paused
 */
const isTimerPausedSelector = selector({
    key: 'isTimerPaused',
    get: ({get}) => {
        /** @type ?Step */
        const currentStep = get(currentStepSelector);
        /** @type Index */
        const elementIndex = get(elementIndexAtom);

        /** @type boolean */
        return checkIfTimerIsPaused(currentStep, elementIndex.value);
    }
});

/**
 * Return the number of instructions for the current step
 */
const numberOfInstructionsSelector = selector({
    key: 'numberOfInstructions',
    get: ({get}) => {
        /** @type Step */
        const currentStep = get(currentStepSelector);

        return currentStep?.instructions?.length || 0;
    }
});

/**
 * Return the previous Step object based on the value of the assessment selector and stepIndex atom.
 */
const previousStepSelector = selector({
    key: 'previousStep',
    get: ({get}) => {
        /** @type Assessment|T */
        const assessment = get(assessmentSelector);
        /** @type Index */
        const stepIndex = get(stepIndexAtom);

        /** @type ?Step */
        return assessment?.steps?.[stepIndex.value - 1] || null;
    }
});

/**
 * Return the id of the previous step.
 */
const previousStepIdSelector = selector({
    key: 'previousStepId',
    get: ({get}) => {
        /** @type Step */
        const previousStep = get(previousStepSelector);

        /** @type ?string */
        return previousStep?.id || null;
    }
});

/**
 * Return the maximum element index of the current step.
 */
const previousStepMaxElementIndexSelector = selector({
    key: 'previousStepMaxElementIndex',
    get: ({get}) => {
        /** @type ?Step */
        const previousStep = get(previousStepSelector);
        /** @type number */
        const elementCount = countElements(previousStep);

        /** @type number */
        return elementCount - 1;
    }
});

/**
 * Determine the start time of the current step. When the step already has a progression, returns the start time from
 * that progression. When there is no progression and no active pause, returns the current time (i.e., starts the step
 * now). When a not-yet-started step still has a paused timer, returns null.
 */
const startTimeOfCurrentStepSelector = selector({
    key: 'startTimeOfCurrentStep',
    get: ({get}) => {
        /** @type ?string */
        const currentStepId = get(currentStepIdSelector);
        /** @type Progression[] */
        const progressions = get(progressionsAtom);
        /** @type boolean */
        const isTimerPaused = get(isTimerPausedSelector);

        // There can't be a start time when there is no current step
        if (!currentStepId) return null;

        /** @type {?Progression} - Look for a matching progression indicating that the current step has been started.*/
        const progression = progressions.find((progression) =>
            progression.step === currentStepId &&
            progression.type === 'S' &&
            !progression.deletedAt
        );

        if (!!progression?.createdAt) {
            /** @type {Date} - When the step has already started, parse the date into a Date object */
            return new Date(progression.createdAt);
        } else if (isTimerPaused) {
            /** @type {null} - A paused timer means the step shouldn't start yet. Return null. */
            return null
        }

        /** @type {Date} - An un-paused timer means the step starts now. */
        return new Date();
    }
});

export {
    currentStepSelector,
    currentStepMaxElementIndexSelector,
    currentStepIdSelector,
    hasStartedCurrentStepSelector,
    indexOfLastElementSelector,
    indexOfNextUnansweredElementSelector,
    indexOfPreviousUnansweredElementSelector,
    isTimerPausedSelector,
    numberOfInstructionsSelector,
    previousStepSelector,
    previousStepIdSelector,
    previousStepMaxElementIndexSelector,
    startTimeOfCurrentStepSelector
};