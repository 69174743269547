import { atom } from "recoil";

/**
 * The id of the currently active assessment. A string-formatted uuid or an empty string when there is no active
 * assessment.
 */
const assessmentIdAtom = atom({
  key: 'assessmentId',
  default: ''
});

export { assessmentIdAtom };