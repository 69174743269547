import { atom } from 'recoil';

/**
 * A string with format h:mm:ss or m:ss showing the amount of time remaining to complete a given step.
 */
const timeRemainingAtom = atom({
    key: 'timeRemaining',
    default: null
});

export { timeRemainingAtom };