import { atom } from 'recoil';

/**
 * The login code for use in future logins
 */
const loginCodeAtom = atom({
    key: 'loginCode',
    default:
    /** @type LoginCode */
    {
        id: '',
        batch: '',
        createdAt: '',
        customIdField: {
            description: '',
            issuer: '',
            loginCode: '',
            placeholder: '',
            regex: '',
        },
        expiresAt: '',
        project: '',
        respondent: '',
        value: ''
    }
});

export { loginCodeAtom };