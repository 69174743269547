/**
 * Append to the list of previous status codes, returning a list of the unique status codes already seen
 * @param {string[]} previousStatusCodes - The list of previous status codes
 * @param {string} newStatusCode - The new status code to append
 * @return {string[]}
 */
export function appendToPreviousStatusCodes(previousStatusCodes, newStatusCode) {
    return [...new Set([...previousStatusCodes, newStatusCode])];
}

/**
 * Check whether to display an error message on the login screen
 * @param {LoginStatus} loginStatus - The login status object
 * @return {boolean}
 */
export function checkForErrorMessage(loginStatus) {
    /** @type boolean */
    const isStatusRepeated = loginStatus?.previousStatusCodes?.includes(loginStatus?.currentStatusCode);
    /** @type boolean */
    const statusCodeStartsWithM = loginStatus?.currentStatusCode?.startsWith("M");
    /** @type boolean */
    return !!loginStatus?.errorMessage && (!statusCodeStartsWithM || isStatusRepeated);
}

/**
 * Set the state of the loginStatus atom after a failed login attempt.
 * @param {object} data - The data returned by the API in response to the failed login attempt. A Login object.
 * @param {function} setLoginStatus - Function to set the state of the login status atom
 * @return {void}
 */
export function setLoginStatusAfterFailedLogin(data, setLoginStatus) {
    setLoginStatus((prev) => (
        /** @type {LoginStatus|any} */
        {
            currentStatusCode: data?.status || 'ML',
            previousStatusCodes: appendToPreviousStatusCodes(prev.previousStatusCodes, prev.currentStatusCode),
            errorMessage: data?.description || ''
        }
    ));
}

/**
 * When a login ID and JWT token was returned by the API, store session info
 * @param {string} loginId - The login id returned from the API
 * @param {string} accessToken - The access token returned from the API
 * @param {string} refreshToken - The refresh token returned from the API
 * @return {void}
 */
export function storeSessionInfo(loginId, accessToken, refreshToken) {
    if (!loginId || !accessToken || !refreshToken) return;

    const session = {
        'id': loginId,
        'accessToken': accessToken,
        'refreshToken': refreshToken
    };
    const sessionJson = JSON.stringify(session);
    sessionStorage.setItem('session', sessionJson);
}