import { atom } from "recoil";

/**
 * A list of Demographic objects indicating the respondent's responses to each of the demographic Fields.
 */
const demographicsAtom = atom({
  key: 'demographics',
  default: []
});

export { demographicsAtom };