import { selector } from "recoil";
import { answersAtom, demographicsAtom, elementIndexAtom } from "_atoms";
import { currentStepSelector, previousStepSelector } from "_selectors";
import { filterPageElements } from "_helpers/page-helpers";
import { determineNavLinks } from "_helpers/nav-helpers";

/**
 * Create a page object defining which elements to display on the screen
 */
const pageSelector = selector({
    key: 'page',
    /**
     * Create a Page object defining what content to show on screen
     * @return {Page}
     */
    get: ({get}) => {
        /** @type Step */
        const currentStep = get(currentStepSelector);
        /** @type Index */
        const elementIndex = get(elementIndexAtom);
        /** @type Answer[] */
        const answers = get(answersAtom);
        /** @type Demographic[] */
        const demographics = get(demographicsAtom);
        /** @type Step */
        const previousStep = get(previousStepSelector);
        /** @type boolean */
        const canReenterPreviousStep = !!previousStep?.canReenterAfterCompletion;
        /** @type [(Instruction|Item|Field)[], number, number] */
        const [elementsOnPage, endIndex, startIndex] = filterPageElements(elementIndex.value, currentStep);
        /** @type [boolean, boolean, boolean, boolean, boolean] */
        const [hasBackwardNav, hasForwardNav, hasJumpBackwardNav, hasJumpForwardNav, hasJumpToLastNav] = determineNavLinks(
            answers, canReenterPreviousStep, demographics, elementsOnPage, endIndex, startIndex, currentStep
        );

        return (
            /** @type Page */
            {
                elements: elementsOnPage,
                endIndex: endIndex,
                hasBackwardNav: hasBackwardNav,
                hasForwardNav: hasForwardNav,
                hasJumpBackwardNav: hasJumpBackwardNav,
                hasJumpForwardNav: hasJumpForwardNav,
                hasJumpToLastNav: hasJumpToLastNav,
                startIndex: startIndex
            }
        )
    }
});

export { pageSelector };