import { useEffect, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import axios from "axios";
import { useRecoilValue, useSetRecoilState, useResetRecoilState } from "recoil";
import { useErrorBoundary } from "react-error-boundary";
import {
    answersAtom,
    assessmentIdAtom,
    demographicsAtom,
    loginCodeAtom,
    loginFormAtom,
    loginStatusAtom,
    progressionsAtom,
    respondentAtom,
    userAtom
} from '_atoms';
import { respondentIdSelector } from '_selectors';
import {setLoginStatusAfterFailedLogin, storeSessionInfo} from "_helpers/login-helpers";
import { loginGetQuery, loginPostQuery } from "_queries/login-queries";

/**
 * Upon first loading the app, check if login info is stored in the session or url params. When info is stored in the
 * session, reload the app state from the API. When url params have a login code value, post a new login.
 */
const useSession = () => {
    /** @type string */
    const respondentId = useRecoilValue(respondentIdSelector);
    /** @type {Dispatch<SetStateAction<LoginCode>>} */
    const setLoginCode = useSetRecoilState(loginCodeAtom);
    /** @type Dispatch<SetStateAction<LoginForm>> */
    const setLoginForm = useSetRecoilState(loginFormAtom);
    /** @type Dispatch<SetStateAction<LoginStatus>> */
    const setLoginStatus = useSetRecoilState(loginStatusAtom);
    /** @type function */
    const resetAnswers = useResetRecoilState(answersAtom);
    /** @type function */
    const resetAssessmentId = useResetRecoilState(assessmentIdAtom);
    /** @type function */
    const resetDemographics = useResetRecoilState(demographicsAtom);
    /** @type function */
    const resetLoginForm = useResetRecoilState(loginFormAtom);
    /** @type function */
    const resetLoginStatus = useResetRecoilState(loginStatusAtom);
    /** @type function */
    const resetProgressions = useResetRecoilState(progressionsAtom);
    /** @type function */
    const resetRespondent = useResetRecoilState(respondentAtom);
    /** @type function */
    const resetUser = useResetRecoilState(userAtom);

    /** @type URLSearchParams */
    const [ searchParams ] = useSearchParams();

    /** Hook provided by react-error-boundary for sharing errors with the nearest boundary */
    const { showBoundary } = useErrorBoundary();

    // The hook should only run once upon initial rendering of the React app. All parameters are passed by reference
    // so that updates to the parameters do not trigger the hook to run again.
    /** @type MutableRefObject<string> */
    const refRespondentId = useRef(respondentId);
    /** @type MutableRefObject<URLSearchParams> */
    const refSearchParams = useRef(searchParams);

    useEffect(() => {
        /** @type {AbortController} - Use an abort controller to cancel API requests if the component unmounts */
        const controller = new AbortController();
        /** @type {Session} - The current session information */
        const session = JSON.parse(sessionStorage.getItem('session'));

        /**
        * Handle the results of a failed request from the API.
        * @param {AxiosError|AxiosResponse} axiosError - The axios error object returned after the API call
        * @return {void}
        */
        function handleFailure(axiosError) {
            try {
                if (axios.isCancel(axiosError)) return;

                // Update the login status to reflect the failures
                const data = axiosError?.response?.data || axiosError?.data;
                setLoginStatusAfterFailedLogin(data, setLoginStatus);

                // If a login code was returned, set it
                if (data?.loginCode?.id) {
                    setLoginCode(data.loginCode);
                }

                // Reset the app state back to its default status
                resetAnswers();
                resetAssessmentId();
                resetDemographics();
                resetProgressions();
                resetRespondent();
                resetUser();
            } catch(error) {
                console.log({'error': error, 'axiosError': axiosError});
                sessionStorage.removeItem('session');

                //Display the nearest error boundary
                const errorMessage = axiosError?.response?.data?.message || axiosError?.data?.message || axiosError;
                showBoundary(errorMessage);
            }
        }

        /**
        * Handle the results of a successful request from the API.
        * @param {AxiosResponse} axiosResponse - The axios response object returned after the API call
        * @return {void}
        */
        function handleSuccess(axiosResponse) {
            try {
                const data = axiosResponse?.data;
                storeSessionInfo(data?.id, data?.token?.access, data?.token?.refresh);
                setLoginCode(data.loginCode);
                setLoginStatus(
                    /** @type LoginStatus */
                    {
                        currentStatusCode: data?.status,
                        errorMessage: '',
                        previousStatusCodes: []
                    }
                );
            } catch(error) {
                handleFailure(axiosResponse);
            }
        }

        if (!!session?.id && !refRespondentId?.current) {
            // When login info is present in the session but the respondent isn't already logged in, use session info
            // to retrieve the information for the given login id
            setLoginStatus(
                /** @type LoginStatus */
                {
                    currentStatusCode: 'PL',
                    errorMessage: '',
                    previousStatusCodes: []
                }
            );
            loginGetQuery(session.id, controller).then(handleSuccess).catch(handleFailure);
        } else if (refSearchParams.current.get('loginCode')) {
            /** @type {LoginForm} - When the url params contain info to log in (a login code value), use it to log in */
            const loginForm = {
                backupOneTimePassword: null,
                customIdValue: refSearchParams.current.get('customId'),
                loginCodeValue: refSearchParams.current.get('loginCode'),
                password: null,
                oneTimePassword: null
            }
            setLoginForm(loginForm);
            refSearchParams.current.delete('loginCode');
            loginPostQuery(loginForm).then(handleSuccess).catch(handleFailure);
        }

        // When the effect unmounts, abort any active API requests
        return () => {
            controller.abort();
        };
    }, [refRespondentId, refSearchParams, resetAnswers, resetAssessmentId, resetDemographics, resetLoginForm,
        resetLoginStatus, resetProgressions, resetRespondent, resetUser, setLoginCode, setLoginForm, setLoginStatus,
        showBoundary]);
}

export { useSession };