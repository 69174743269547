import { api } from "./query-interceptors";

/**
 * Attempt to log in, posting a new login to the API
 * @param {LoginForm} loginForm - The current state of the login form fields
 * @return {Promise} - Returns the axios request promise.
 */
export async function loginPostQuery(loginForm) {
    const loginFormWithExpandParam = {
        ...loginForm,
        expand: 'loginCode.customIdField'
    }
    const requestOptions = {
        method: 'POST',
        url: `/logins/`,
        data: loginFormWithExpandParam
    };
    return api(requestOptions);
}

/**
 * Retrieve info associated with a previously existing login
 * @param {string} loginId - ID of the login session to retrieve from the API
 * @param {AbortController} controller - The abort controller to cancel requests
 * @return {Promise} - Returns the axios request promise.
 */
export async function loginGetQuery(loginId, controller) {
    const requestOptions = {
        method: 'GET',
        url: `/logins/${loginId}/`,
        params: {
            expand: 'loginCode.customIdField'
        },
        signal: controller.signal
    };
    return api(requestOptions);
}