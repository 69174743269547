import { atom } from 'recoil';

/**
 * Boolean of whether the login code off canvas is currently visible
 */
const isLoginCodeVisibleAtom = atom({
    key: 'isLoginCodeVisible',
    default: true
});

export { isLoginCodeVisibleAtom };