import { useEffect } from "react";
import { useRecoilState } from 'recoil';
import { demographicsAtom } from "_atoms";
import { createRevisedListAfterSave, getUnsavedReplies } from "_helpers/reply-helpers";
import { demographicsPostQuery } from "_queries/demographics-queries";
import useAxiosError from "_actions/axios-error-actions";

/**
* When the list of answers changes, check if it is time to post unsaved ones to the API.
*/
const useDemographics = () => {
    /** @type [Demographic[], Dispatch<SetStateAction<Demographic[]>>] */
    const [demographics, setDemographics] = useRecoilState(demographicsAtom);

    /** Actions to handle axios errors */
    const { handleAxiosError } = useAxiosError();

    /**
     * Whenever the list of demographics changes, check if it's time to post the unsaved ones
     */
    useEffect(() => {
        /** @type {AbortController} - Use an abort controller to cancel API requests if the component unmounts */
        const controller = new AbortController();
        /** @type (Answer|Demographic)[] */
        const unsavedDemographics = getUnsavedReplies(demographics);

        /**
        * After demographics are posted to the database, update the list of demographics in the app state with
        * isSaved property set to true for all the newly saved ones.
        * @param {AxiosResponse} axiosResponse - The axios response object returned after the API call
        * @return {void}
        */
        function handleSuccess(axiosResponse) {
            try {
                const revisedDemographics = createRevisedListAfterSave(axiosResponse.data.results, demographics);
                setDemographics(revisedDemographics);
            } catch (error) {
                console.log(error);
            }
        }

        // When there are unsaved demographics, post unsaved demographics to the API
        if (unsavedDemographics?.length > 0) {
            demographicsPostQuery(unsavedDemographics, controller).then(handleSuccess).catch(handleAxiosError);
        }

        // When the effect unmounts, abort any active API requests
        return () => {
            controller.abort();
        };
    }, [demographics, handleAxiosError, setDemographics]);
}

export { useDemographics };