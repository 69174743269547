import { atom } from 'recoil';

/**
 * A LoginStatus object recording (1) the login status code (a 2-character string) for the most recent attempt,
 * (2) a list of the unique previous status codes, (3) an error message to show on screen.
 */
const loginStatusAtom = atom({
    key: 'loginStatus',
    default:
    /** @type LoginStatus */
    {
        currentStatusCode: 'ML',
        errorMessage: '',
        previousStatusCodes: []
    }
});

export { loginStatusAtom };