import React from "react";
import Card from 'react-bootstrap/Card'
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

const CardBodyMarkdown = ({ value }) => {
    return (
        <Card.Body>
            <ReactMarkdown remarkPlugins={[remarkGfm]}>{value}</ReactMarkdown>
        </Card.Body>
    );
}

export default CardBodyMarkdown;