import {
    useAnswers,
    useDemographics,
    useLanguage,
    useLoginCode,
    useProgressions,
    useRespondent,
    useSession,
    useNavigation
} from "_effects";

/**
 * @constructor
 * No matter what feature or components are being displayed, listen for changes to the following atoms and selectors
 * and post any unsaved changes to the API.
 */
const AppListeners = () => {
    useAnswers();         // Post any newly added answers to the API
    useDemographics();    // Post any newly added demographic replies to the API
    useLanguage();        // Use the language atom to keep i18n and the respondent atom language in sync
    useLoginCode();       // When the login code atom changes, load respondent info from the API
    useProgressions();    // Post any newly added progressions to the API
    useRespondent();      // Post updates to the respondent's chosen language for completing materials
    useSession();         // Use any session variables to log in upon first loading of the app
    useNavigation();      // Navigate between app pages when certain events occur

    return null;
}

export default AppListeners;