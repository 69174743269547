import { api } from "./query-interceptors";

/**
 * Retrieve the list of languages from the API.
 * @param {AbortController} controller - The abort controller to cancel requests
 * @return {Promise} - Returns the axios request promise.
 */
export async function languagesGetQuery( controller) {
    const requestOptions = {
        method: 'GET',
        url: `/languages/`,
        signal: controller.signal
    };
    return api(requestOptions);
}