import { selector } from "recoil";
import { assessmentIdAtom, progressionsAtom } from "_atoms";
import { batchSelector } from "_selectors";

/**
 * Return the list of Material objects for the batch.
 */
const materialsSelector = selector({
    key: 'materials',
    get: ({get}) => {
        /** @type Batch|T */
        const batch = get(batchSelector);

        /** @type Material[] */
        return batch?.materials;
    }
});

/**
 * Determine if the respondent has completed all required materials. Boolean.
 */
const hasCompletedAllMaterialsSelector = selector({
    key: 'hasCompletedAllMaterials',
    get: ({get}) => {
        /** @type Material[] */
        const materials = get(materialsSelector);
        /** @type Progression[] */
        const progressions = get(progressionsAtom);

        // When there is no batch info or no progressions, materials cannot possibly be complete
        if (!materials?.length || !progressions?.length) return false;

        /** @type {boolean} - Return true when there is no material without a completion progression */
        return materials.findIndex((material) =>
            !progressions.some((progression) =>
                progression?.assessment === material?.assessment &&
                progression?.type === 'C' &&
                !progression?.deletedAt
            )
        ) === -1;
    }
});

/**
 * Determine if the respondent has completed all required materials. Boolean.
 */
const hasCompletedAllRequiredMaterialsSelector = selector({
    key: 'hasCompletedAllRequiredMaterials',
    get: ({get}) => {
        /** @type Material[] */
        const materials = get(materialsSelector);
        /** @type Progression[] */
        const progressions = get(progressionsAtom);

        // When there is no batch info or no progressions, materials cannot possibly be complete
        if (!materials?.length || !progressions?.length) return false;

        /** @type {boolean} - Return true when there is no required material without a completion progression */
        return materials.findIndex((material) =>
            material.isRequired &&
            !progressions.some((progression) =>
                progression?.assessment === material?.assessment &&
                progression?.type === 'C' &&
                !progression?.deletedAt
            )
        ) === -1;
    }
});

/**
 * Determine if there are any materials in the batch that are optional rather than required. Boolean.
 */
const hasOptionalMaterialsSelector = selector({
    key: 'hasOptionalMaterials',
    get: ({get}) => {
        /** @type Material[] */
        const materials = get(materialsSelector);

        // When there are no materials, there are no optional materials
        if (!materials?.length) return false;

        /** @type {boolean} - Return true when there are any materials that are not required */
        return materials.findIndex((material) => !material?.isRequired) > -1;
    }
});

/**
 * Determine if any of the materials can be reentered after they have been completed.
 */
const hasReenterableMaterialsSelector = selector({
    key: 'hasReenterableMaterials',
    get: ({get}) => {
        /** @type Material[] */
        const materials = get(materialsSelector);

        // When there are no materials, there are none that can be re-entered
        if (!materials?.length) return false;

        /** @type {boolean} - Return true when there are any materials that can be re-entered after being completed */
        return materials.findIndex((material) => material?.canReenterAfterCompletion === true) > -1;
    }
});

/**
 * Check whether the currently active assessment is the last material administered in the batch
 */
const isLastMaterialSelector = selector({
    key: 'isLastMaterial',
    get: ({get}) => {
        /** @type string */
        const activeAssessmentId = get(assessmentIdAtom);
        /** @type Material[] */
        const materials = get(materialsSelector);

        if (!materials?.length || !activeAssessmentId) return false;

        /** @type string[] */
        const assessmentIds = materials.map((material) => material.assessment);
        /** @type ?string */
        const lastAssessmentId = assessmentIds.at(-1);

        /** @type boolean */
        return lastAssessmentId === activeAssessmentId;
    }
});

/**
 * Get the slug of the currently active assessment
 */
const slugOfActiveAssessmentSelector = selector({
    key: 'slugOfActiveAssessment',
    get: ({get}) => {
        /** @type string */
        const activeAssessmentId = get(assessmentIdAtom);
        /** @type Material[] */
        const materials = get(materialsSelector);

        // When there are no materials or no active assessment, there is no slug
        if (!materials?.length || !activeAssessmentId) return null;

        /** @type Material */
        const activeMaterial = materials.find((material) => material.assessment === activeAssessmentId);

        /** @type string */
        return activeMaterial.slug;
    }
});

export {
    materialsSelector,
    hasCompletedAllMaterialsSelector,
    hasCompletedAllRequiredMaterialsSelector,
    hasOptionalMaterialsSelector,
    hasReenterableMaterialsSelector,
    isLastMaterialSelector,
    slugOfActiveAssessmentSelector
};