/**
 * Check if all thresholds have a corresponding score object
 * @param {Score[]} scores - The current list of scores
 * @param {Threshold[]} thresholds - The list of thresholds to check for scores
 * @return {boolean} - Returns true when all supplied thresholds have a corresponding score object
 */
export function checkIfAllThresholdsHaveScores(scores, thresholds) {
    return thresholds?.findIndex((threshold) =>
        !checkIfThresholdHasScore(scores, threshold)
    ) === -1
}

/**
 * Check if a careless response check has a matching progression object within a given list of progression
 * @param {CarelessResponseCheck} carelessResponseCheck - The careless response check for which to search
 * @param {Progression[]} progressions - The list of progressions in which to find a matching one
 * @return {boolean} - Returns true when the supplied careless response check has a matching progression
 */
export function checkForMatchingProgression(carelessResponseCheck, progressions) {
    return progressions.findIndex((progression) =>
        progression.step === carelessResponseCheck.calculateAfterStep
    ) > -1;
}

/**
 * Check if a particular threshold has a corresponding score object
 * @param {Score[]} scores - The current list of scores
 * @param {Threshold} threshold - The threshold to check for a score
 * @return {boolean} - Returns true the threshold has a corresponding score object
 */
export function checkIfThresholdHasScore(scores, threshold) {
    return scores?.findIndex((score) => score.scale === threshold.scale) > -1
}

/**
 * After progressions are posted to the database, create a revised list of progressions suitable for saving in the app
 * state with the isSaved property set to true for all the newly added ones.
 * @param {CarelessResponseCheck} carelessResponseCheck - The careless response check that was failed
 * @param {Answer[]} answers - The original list of answers
 * @return {(Answer|Progression)[]} - Returns the new list of answers to be saved to the app state
 */
export function createRevisedListOfAnswersAfterReset(carelessResponseCheck, answers) {
    const answersToFlag = getRelevantAnswers(answers, carelessResponseCheck);
    const unaffectedAnswers = exclude(answers, answersToFlag);
    const flaggedAnswers = setDeletedAt(answersToFlag);
    return [...unaffectedAnswers, ...flaggedAnswers];
}

/**
 * When the respondent opts to retry materials after failing a careless response check, flag the relevant progressions
 * as deleted.
 * @param {CarelessResponseCheck} carelessResponseCheck - The careless response check that was failed
 * @param {Progression[]} progressions - The original list of progressions currently in state memory
 * @return {(Progression|Answer)[]} - Returns the new list of progressions to be saved to the app state
 */
export function createRevisedListOfProgressionsAfterReset(carelessResponseCheck, progressions) {
    const progressionsToFlag = getRelevantProgressions(carelessResponseCheck, progressions);
    const unaffectedProgressions = exclude(progressions, progressionsToFlag);
    const flaggedProgressions = setDeletedAt(progressionsToFlag);
    return [...unaffectedProgressions, ...flaggedProgressions];
}

/**
 * After progressions are posted to the database, create a revised list of progressions suitable for saving in the app
 * state with the isSaved property set to true for all the newly added ones.
 * @param {CarelessResponseCheck} carelessResponseCheck - The careless response check that was failed
 * @param {Progression[]} progressions - The original list of progressions
 * @return {(Progression|Answer)[]} - Returns the new list of progressions to be saved to the app state
 */
export function createRevisedListOfProgressionsAfterIgnore(carelessResponseCheck, progressions) {
    const progressionsToIgnore = getRelevantProgressions(carelessResponseCheck, progressions);
    const unaffectedProgressions = exclude(progressions, progressionsToIgnore);
    const ignoredProgressions = setIgnoredAt(progressionsToIgnore);
    return [...unaffectedProgressions, ...ignoredProgressions];
}

/**
 * Filter a list of objects, removing a subset of objects from a larger list.
 * @param {(Progression|Answer)[]} allObjects - The complete list of progressions or answers being filtered
 * @param {(Progression|Answer)[]} objectsToExclude - The list of progressions or answers to exclude from the returned list.
 * @return {(Progression|Answer)[]} - Returns a filtered list of progressions with the newlySavedProgressions left out.
 */
function exclude(allObjects, objectsToExclude) {
    return allObjects.filter((object) =>
        !objectsToExclude.some((excluded) => object.id === excluded.id)
    );
}

/**
 * Create a list of the explanations for all failed thresholds for a given careless response check
 * @param {CarelessResponseCheck} carelessResponseCheck - The careless response check for which to create explanations
 * @param {Score[]} scores - Current list of scores, used to let the respondent know how they did on the threshold
 * @return {string[]} - Returns a list of Markdown text stings
 */
export function getExplanationsForFailedThresholds(carelessResponseCheck, scores) {
    /** @type {Threshold[]} - Create a list of the thresholds failed for the given careless response check */
    const failedThresholds = getFailedThresholds(carelessResponseCheck, scores);

    return failedThresholds.map((threshold) => {
        const score = scores.find((score) => score.scale === threshold.scale);
        const formattedScoreValue = Number(score?.value).toFixed(threshold.decimals)
        return threshold.explanation.replace("[score]", formattedScoreValue);
    });
}

/**
 * Get all answers relevant to a given careless response check
 * @param {Answer[]} answers - The current list of answers
 * @param {CarelessResponseCheck} carelessResponseCheck - The careless response check that was failed
 * @return {Answer[]} - Returns the new list of answers to be saved to the app state
 */
function getRelevantAnswers(answers, carelessResponseCheck) {
    const itemsInEachStep = carelessResponseCheck?.stepsToReset?.map((step) => {return step?.items});
    const flattenedItemIds = [].concat.apply([], itemsInEachStep);

    return answers.filter((answer) => flattenedItemIds.includes(answer.item));
}

/**
 * Validate a list of careless response checks, checking if any thresholds have been met.
 * @param {CarelessResponseCheck} carelessResponseCheck - The careless response check to run
 * @param {Score[]} scores - Current list of scores to check thresholds against
 * @return {Threshold[]} - Returns the list of failed thresholds
 */
export function getFailedThresholds(carelessResponseCheck, scores) {
    return carelessResponseCheck?.thresholds?.filter((threshold) => {
        /** @type {Score} - Get the relevant score for the given threshold */
        const score = scores.find((score) => score.scale === threshold.scale);

        /** @type {boolean} - Validate whether the score meets the required threshold */
        const isValid = validateScore(score?.value, threshold.value, threshold.type)

        /** @type {boolean} - Include the careless response check in the returned list when validation fails */
        return !isValid;
    }) || [];
}

/**
 * Get all relevant progressions (in stepsToReset) associated with a particular careless response check.
 * @param {CarelessResponseCheck} carelessResponseCheck - The careless response check for which to get progressions
 * @param {Progression[]} progressions - The list of all current progressions
 * @return {Progression[]} - Returns the filtered list of progressions containing just the relevant ones
 */
export function getRelevantProgressions(carelessResponseCheck, progressions) {
    const idsOfSteps = carelessResponseCheck?.stepsToReset?.map((step) => step.id);
    return progressions.filter((progression) => idsOfSteps?.includes(progression?.step));
}

/**
 * Validate a list of careless response checks, checking if any thresholds have been met.
 * @param {CarelessResponseCheck} carelessResponseCheck - The careless response check to run
 * @param {Score[]} scores - Current list of scores to check thresholds against
 * @return {boolean} - Returns true when the check passes (penalties of failed thresholds is less than 100)
 */
export function runCarelessResponseCheck(carelessResponseCheck, scores) {
    /** @type {Threshold[]} - Create a list of the failed thresholds */
    const failedThresholds = getFailedThresholds(carelessResponseCheck, scores);

    /** @type {Integer[]} - Create an array containing the severities for all the failed thresholds */
    const severitiesFromFailedThresholds = failedThresholds.map((threshold) => { return threshold.severity });

    /** @type {Integer} - Calculate the sum of all the severities from all failed thresholds */
    const sum = severitiesFromFailedThresholds.reduce((partialSum, a) => partialSum + a, 0);

    /** @type {boolean} - The careless response check passes when the severities from all failed thresholds sum to less
     *                    than 100 */
    return (sum < 100);
}

/**
 * Flag a list of objects (progressions or answers) as deleted. Any non-null deletedAt properties will be updated to
 * have the current date.
 * @param {(Progression|Answer)[]} objects - List of objects for which to append a deletedAt property
 * @return {(Progression|Answer)[]} - Returns a new list of objects where each one has a non-null deletedAt
 *                                            property
 */
function setDeletedAt(objects) {
    const currentDate = new Date();
    const formattedCurrentDate = currentDate.toISOString();
    return objects.map((object) => (
        /** @type {Progression|Answer} */
        {
            ...object,
            deletedAt: object?.deletedAt || formattedCurrentDate,
            // When the object was already saved and flagged as deleted, no need to save it again
            isSaved: object?.isSaved && !!object?.deletedAt
        }
    ));
}

/**
 * Flag a list of progressions as ignored. Any non-null ignoredAt properties will be updated to have the current date.
 * @param {Progression[]} progressions - The list of progressions for which to append a ignoredAt property
 * @return {Progression[]} - Returns a new list of progressions where each one has a non-null ignoredAt property
 */
function setIgnoredAt(progressions) {
    const currentDate = new Date();
    const formattedCurrentDate = currentDate.toISOString();
    return progressions.map((progression) => (
        /** @type Progression */
        {
            ...progression,
            ignoredAt: progression?.ignoredAt || formattedCurrentDate,
            // When the progression was already saved and flagged as ignored, no need to save it again
            isSaved: progression?.isSaved && !!progression?.ignoredAt
        }
    ));
}

/**
 * Validate whether a score meets a given threshold
 * @param {?number} scoreValue - The score value being tested
 * @param {number} thresholdValue - The threshold the score is being compared to
 * @param {'MAX'|'MIN'} thresholdType - When MAX, score can't exceed the threshold. When MIN, score can't fall below the
 *                                      threshold.
 * @return {boolean} - Returns true when the score passes validation
 */
export function validateScore(scoreValue, thresholdValue, thresholdType, ) {
    if (scoreValue === null) {
        // When the score value is missing/null, threshold validation fails.
        return false;
    } else if (thresholdType === 'MAX' && scoreValue > thresholdValue) {
        // When the score value exceeds the max threshold, threshold fails validation
        return false
    } else if (thresholdType === 'MIN' && scoreValue < thresholdValue) {
        // When the score value is below the min threshold, threshold fails validation
        return false;
    }
    // Default to the threshold passing validation
    return true;
}