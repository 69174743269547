import { api } from "./query-interceptors";

/**
 * Retrieve the list of a respondent's non-deleted and non-ignored replies to demographic fields from the API.
 * @param {string} respondentId - The id of the respondent for whom to fetch the demographics
 * @param {AbortController} controller - The abort controller to cancel requests
 * @return {Promise} - Returns the axios request promise.
 */
export async function demographicsGetQuery( respondentId, controller) {
    const requestOptions = {
        method: 'GET',
        url: `/demographics/`,
        params: {
            deletedAt: null,
            ignoredAt: null,
            respondent: respondentId
        },
        signal: controller.signal
    };
    return api(requestOptions);
}

/**
 * Post a list of demographics to the database.
 * @param {(Answer|Demographic)[]} demographicsToPost - The list of demographics to save via the API
 * @param {AbortController} controller - The abort controller to cancel requests
 * @return {Promise} - Returns the axios request promise.
 */
export async function demographicsPostQuery(demographicsToPost, controller) {
    const requestOptions = {
        method: 'POST',
        url: `/demographics/`,
        data: demographicsToPost,
        signal: controller.signal
    };
    return api(requestOptions);
}

/**
 * Post a single demographic to the database. When posting a file, can only post one demographic.
 * @param {Demographic} demographic - The demographic to save via the API posted as a FormData object
 * @param {AbortController} controller - The abort controller to cancel requests
 * @return {Promise} - Returns the axios request promise.
 */
export async function demographicFilePostQuery(demographic, controller) {
    let formData = new FormData();
    formData.append('id', demographic?.id);
    formData.append('createdAt', demographic?.createdAt);
    formData.append('field', demographic?.field);
    formData.append('file', demographic?.file, demographic?.file?.name);
    formData.append('text', demographic?.text);
    formData.append('respondent', demographic?.respondent);

    demographic?.groups?.forEach((groupId) => {
        formData.append('groups', groupId)
    });

    const requestOptions = {
        method: 'POST',
        url: `/demographics/`,
        data: formData,
        signal: controller.signal
    };
    return api(requestOptions);
}