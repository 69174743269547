import { useState } from "react";
import { useSetRecoilState, useRecoilValue } from "recoil";
import { useTranslation } from "react-i18next";
import { languageAtom } from "_atoms";
import { availableLanguagesSelector } from "_selectors";
import LanguageSelectBox from "Login/components/LanguageSelectBox/LanguageSelectBox";
import LanguageCard from "Login/components/LanguageCard/LanguageCard";

const LanguageFormContainer = () => {
    /** @type Language[] */
    const availableLanguages = useRecoilValue(availableLanguagesSelector);
    /** @type Dispatch<SetStateAction<string>>] */
    const setLanguage = useSetRecoilState(languageAtom);
    /** @type [string, Dispatch<SetStateAction<string>>] */
    const [languageSelectBoxValue, setLanguageSelectBoxValue] = useState(availableLanguages?.[0]?.id);
    /** @type TFunction<"translation", undefined>*/
    const { t } = useTranslation();
    /** @type i18n */
    const { i18n } = useTranslation();

    function handleChange(e) {
        setLanguageSelectBoxValue(e.target.value);
        return i18n.changeLanguage(e.target.value);
    }

    function handleClick() {
        setLanguage(languageSelectBoxValue);
        localStorage.setItem('i18nextLng', languageSelectBoxValue);
    }

    const languageSelectBox = <LanguageSelectBox
        languages={availableLanguages}
        onChange={handleChange}
        value={languageSelectBoxValue}
    />;

    return (
        <LanguageCard
            bodyText={t("login.languageForm.body")}
            buttonLabel={t("login.languageForm.buttonLabel")}
            headerText={t("login.languageForm.header")}
            isButtonDisabled={!languageSelectBoxValue}
            onClick={handleClick}
            selectBox={languageSelectBox}
        />
    );
}

export default LanguageFormContainer;