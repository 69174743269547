import React, { useEffect } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { useTranslation } from "react-i18next";
import {
    languageAtom,
    loginCodeAtom,
    loginFormAtom,
    loginStatusAtom,
} from "_atoms";
import PageLoadingSpinner from "_components/PageLoadingSpinner/PageLoadingSpinner";
import LoginAlert from "Login/containers/LoginAlert";
import LoginInputField from "Login/components/LoginInputField/LoginInputField";
import useLoginActions from "_actions/login-actions";
import { checkForErrorMessage } from "_helpers/login-helpers";
import LanguageFormContainer from "./containers/LanguageFormContainer";

/**
 * Feature for rending login-related components prior to the respondent receiving a valid JWT authentication token
 */
const Login = () => {
    /** @type LoginCode */
    const loginCode = useRecoilValue(loginCodeAtom);
    /** @type [LoginStatus, Dispatch<SetStateAction<LoginStatus>>] */
    const [loginStatus, setLoginStatus] = useRecoilState(loginStatusAtom);
    /** @type [LoginForm, Dispatch<SetStateAction<LoginForm>>] */
    const [loginForm, setLoginForm] = useRecoilState(loginFormAtom);
    if(loginForm?.loginCodeValue){
        localStorage.setItem("loginCode", loginForm?.loginCodeValue);
    }
    /** @type string */
    const language = useRecoilValue(languageAtom);
    /** @type TFunction<"translation", undefined>*/
    const { t } = useTranslation();
    /** @type boolean */
    const hasError = checkForErrorMessage(loginStatus);
    /** @type {string} - The field class name depends on whether an error message is being displayed */
    const errorClassName = hasError ? "is-invalid" : "";

    const loginActions = useLoginActions();

    /**
     * @type {function} - When the input field value changes, update the LoginForm atom and clear any error
     *                    messages being displayed
     */
    const handleChange = (e) => {
        setLoginForm(
            (prev) =>
                /** @type {LoginForm | any} */
                ({ ...prev, [e.target.name]: e.target.value })
        );
        setLoginStatus(
            (prev) =>
                /** @type {LoginStatus | any} */
                ({ ...prev, errorMessage: "" })
        );
        localStorage.setItem("loginCode", e.target.value);
    };

    // useEffect(() => {
    //     localStorage.setItem("loginCode", loginForm?.loginCodeValue);
    // }, [loginForm]);

    /** @type {JSX.Element} - Form for soliciting a login code */
    const loginCodeForm = (
        <LoginInputField
            alert={<LoginAlert />}
            buttonLabel={t("login.loginCodeForm.buttonLabel")}
            changeHandler={handleChange}
            className={errorClassName}
            inputMode="text"
            label={t("login.loginCodeForm.fieldLabel")}
            name="loginCodeValue"
            submitHandler={loginActions.login}
            type="text"
            value={loginForm?.loginCodeValue}
            saveLoginCode={loginForm?.loginCodeValue}
        />
    );

    /** @type {JSX.Element} - Form for soliciting a custom id specific to a given issuer */
    const customIdForm = (
        <LoginInputField
            alert={<LoginAlert />}
            autoComplete="username"
            buttonLabel={t("login.customIdForm.buttonLabel")}
            changeHandler={handleChange}
            className={errorClassName}
            inputMode="text"
            description={loginCode?.customIdField?.description || ""}
            label={loginCode?.customIdField?.placeholder || ""}
            name="customIdValue"
            submitHandler={loginActions.login}
            type="text"
            value={loginForm?.customIdValue}
        />
    );

    /** @type {JSX.Element} - Form for soliciting a password when the respondent has one */
    const passwordForm = (
        <LoginInputField
            alert={<LoginAlert />}
            autoComplete="current-password"
            buttonLabel={t("login.passwordForm.buttonLabel")}
            changeHandler={handleChange}
            className={errorClassName}
            inputMode="text"
            label={t("login.passwordForm.fieldLabel")}
            name="password"
            submitHandler={loginActions.login}
            type="password"
            value={loginForm?.password}
        />
    );

    /** @type {JSX.Element} - Form for soliciting a respondent's one-time password when they have one. */
    const oneTimePasswordForm = (
        <LoginInputField
            alert={<LoginAlert />}
            autoComplete="one-time-code"
            buttonLabel={t("login.oneTimePasswordForm.buttonLabel")}
            changeHandler={handleChange}
            className={`login-one-time-password ${errorClassName}`}
            inputMode="numeric"
            instructions={t("login.oneTimePasswordForm.instructions")}
            label={t("login.oneTimePasswordForm.fieldLabel")}
            maxLength={6}
            name="oneTimePassword"
            pattern={"[0-9]{6}"}
            submitHandler={loginActions.login}
            type="text"
            value={loginForm?.oneTimePassword}
        />
    );

    /**
     * @type {JSX.Element} - When a user with two-factor authentication enabled lost their one-time password
     * device, they can use backup one-time-use recovery tokens to log in.
     */
    const backupOneTimePasswordForm = (
        <LoginInputField
            alert={<LoginAlert />}
            buttonLabel={t("login.backupOneTimePasswordForm.buttonLabel")}
            changeHandler={handleChange}
            className={errorClassName}
            inputMode="text"
            instructions={t("login.backupOneTimePasswordForm.instructions")}
            label={t("login.backupOneTimePasswordForm.fieldLabel")}
            name="backupOneTimePassword"
            submitHandler={loginActions.login}
            type="password"
            value={loginForm?.backupOneTimePassword}
        />
    );

    // The status dictionary defines what form or page loading spinner to show for each possible status code
    const statusDictionary = {
        // Waiting for the results of the assessment fetch query from the API
        PL: <PageLoadingSpinner />,
        // Authenticated with a login code
        AC: <PageLoadingSpinner />,
        // Authenticated and provided a password
        AP: <PageLoadingSpinner />,
        // Authenticated with a valid password and 2-factor authentication
        AV: <PageLoadingSpinner />,
        // Invalid login code
        IL: loginCodeForm,
        // Expired login code
        EL: loginCodeForm,
        // Missing login code
        ML: loginCodeForm,
        // Invalid custom id
        IC: customIdForm,
        // Missing custom id
        MC: customIdForm,
        // Invalid password
        IP: passwordForm,
        // Missing password
        MP: passwordForm,
        // Invalid one-time password (for 2-factor verification)
        IO: oneTimePasswordForm,
        // Missing one-time password (for 2-factor verification)
        MO: oneTimePasswordForm,
        // Invalid backup static one-time password (for 2-factor verification)
        IB: backupOneTimePasswordForm,
        // No 2-factor authentication set up for that user
        NV: (
            <p>
                Submitted an OTP token but user has not set up 2-factor
                verification.
            </p>
        ),
        // No backup tokens set up for that user
        NB: (
            <p>
                Submitted a backup token but user has not set up backup tokens.
            </p>
        ),
    };

    if (!(loginStatus?.currentStatusCode in statusDictionary)) {
        // Verify that the loginStatus code returned by the API is one of the permitted ones
        throw new Error(t("login.statusCodeErrorMessage"));
    } else if (loginCode?.languages?.length > 1 && !language) {
        // When more than one language is available but the language hasn't yet been chosen, show a language picker
        return <LanguageFormContainer />;
    }

    return statusDictionary[loginStatus.currentStatusCode];
};

export default Login;
