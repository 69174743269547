import { api } from "./query-interceptors";

/**
 * Retrieve the list of a respondent's non-deleted and non-ignored progressions from the API.
 * @param {string} respondentId - The id of the respondent for whom to fetch the progressions
 * @param {AbortController} controller - The abort controller to cancel requests
 * @return {Promise} - Returns the axios request promise.
 */
export async function progressionsGetQuery( respondentId, controller) {
    const requestOptions = {
        method: 'GET',
        url: `/progressions/`,
        params: {
            deletedAt: null,
            ignoredAt: null,
            respondent: respondentId
        },
        signal: controller.signal
    };
    return api(requestOptions);
}

/**
 * Post a list of progressions to the database.
 * @param {Progression[]} progressionsToPost - The list of progressions to save to the API
 * @param {AbortController} controller - The abort controller to cancel requests
 * @return {Promise} - Returns the axios request promise.
 */
export async function progressionsPostQuery(progressionsToPost, controller) {
    const requestOptions = {
        method: 'POST',
        url: `/progressions/`,
        data: progressionsToPost,
        signal: controller.signal
    };
    return api(requestOptions);
}