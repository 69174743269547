import { selector } from "recoil";
import { answersAtom } from "_atoms";
import { currentStepSelector } from "_selectors";
import {
    checkForUnsavedRepliesFromDifferentStep,
    checkMaxSecondsBetweenSaves,
    getDateOfFirstUnsavedReply,
    getUnsavedReplies
} from "_helpers/reply-helpers";

/**
 * Determine if it is time to save all unsaved answers. Returns a boolean.
 */
const isTimeToSaveAnswersSelector = selector({
    key: 'isTimeToSaveAnswers',
    get: ({get}) => {
        /** @type Answer[] */
        const answers = get(answersAtom);
        /** @type ?Step */
        const currentStep = get(currentStepSelector);
        /** @type (Answer|Demographic)[] */
        const unsavedAnswers = getUnsavedReplies(answers);

        /**
         * Verifications are performed in serial with the computationally easy ones first to help avoid unnecessary
         * calculations.
         */

        // First, verify that there are unsaved answers.
        if (!unsavedAnswers?.length) return false;

        // Second, check to see if we have reached the maximum number of answers between saves.
        const maxAnswersBetweenSaves = currentStep?.maxAnswersBetweenSaves || 1;
        if (unsavedAnswers.length >= maxAnswersBetweenSaves) return true;

        // Third, look for any unsaved items from a different step on the assessment. If so, trigger a save.
        const itemIdsOnCurrentStep = currentStep?.items?.map((item) => item.id) || [];
        const hasUnsavedAnswersFromDifferentStep = checkForUnsavedRepliesFromDifferentStep(unsavedAnswers, itemIdsOnCurrentStep, 'item');

        if (hasUnsavedAnswersFromDifferentStep) return true;

        // Fourth, verify that there is an active (non-zero) maxSecondsBetweenSaves property.
        const maxSecondsBetweenSaves = currentStep?.maxSecondsBetweenSaves || 0;
        if (!maxSecondsBetweenSaves) return false;

        // Lastly, when there is non-zero maxSecondsBetweenSaves, determine if the threshold has been reached
        const currentTime = new Date();
        const dateOfFirstUnsavedAnswer = getDateOfFirstUnsavedReply(answers);
        const hasMetMaxSeconds = checkMaxSecondsBetweenSaves(maxSecondsBetweenSaves, currentTime, dateOfFirstUnsavedAnswer);

        if (hasMetMaxSeconds) return true;

        /**
         * @type {boolean} - When none of the above conditions for triggering a save have been met, there are no
         *                   answers to save
         */
        return false;
    }
});

export { isTimeToSaveAnswersSelector };