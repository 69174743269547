import { api } from "./query-interceptors";

/**
 * Retrieve information for a given assessment from the database
 * @param {string} assessmentId - The id of the assessment to retrieve from the API
 * @param {AbortController} controller - The abort controller to cancel requests
 * @return {Promise} - Returns the axios request promise.
 */
export async function assessmentGetQuery(assessmentId, controller) {
    const expandString="optionSets,steps";
    const requestOptions = {
        method: 'GET',
        url: `/assessments/${assessmentId}/`,
        params: {
            expand: expandString
        },
        signal: controller.signal
    };
    return api(requestOptions);
}